App.modules.sondages = (() => {
    function Sondages() {
        return document.querySelectorAll('.sondage');
    }
    function Sondage(sondageId) {
        return document.querySelector(`.sondage[data-id="${sondageId}"]`);
    }
    // Fonction pour trier des divs selon l'attribut data-votes en ordre décroissant
    function sortVotes(sondage) {
        // Sélectionner le conteneur des résultats
        const container = sondage.querySelector('.resultats');
        // Collecter tous les divs à trier dans un tableau
        const items = Array.from(container.querySelectorAll('div[data-id]'));

        // Trier les divs par data-votes en décroissant
        items.sort((a, b) => {
            // Extraire les valeurs de data-votes et enlever le signe pourcentage
            const votesA = parseInt(a.querySelector('[data-votes]').getAttribute('data-votes'), 10);
            const votesB = parseInt(b.querySelector('[data-votes]').getAttribute('data-votes'), 10);
            return votesB - votesA; // Pour tri décroissant
        });

        // Réajouter les divs triés au conteneur
        items.forEach(item => container.appendChild(item));
    }


    return {
        voirResultatsSondage(sondageId, resultats) {
            const sondage = Sondage(sondageId)
            sondage.dataset.mode = 'resultats'
            console.log(sondageId, resultats)


            let total = 0;
            let max = 0;
            for (const choixId in resultats.choix) {
                const votes = resultats.choix[choixId]
                if (votes > max) max = votes
                total += votes;
            }
            for (const choixId in resultats.choix) {
                const votes = resultats.choix[choixId]
                const percent = Math.round(100 * votes / total)
                console.log({ choixId, votes, percent })
                const resultat = sondage.querySelector(`.resultats [data-id="${choixId}"]`)
                const texte = resultat.querySelector(`span`)
                if (votes > 10 && votes == max) {
                    setTimeout(() => resultat.dataset.gagnant = true, 500)
                }
                const indicateur = resultat.querySelector('.indicateur');
                texte.dataset.votes = percent + '%'
                setTimeout(() => {
                    indicateur.style.width = percent + '%'
                    // if (resultat.dataset.gagnant) {
                    if (percent > 60) {
                        indicateur.dataset.mode = 'inside'
                    }
                    // }
                }, 500)
            }
            sortVotes(sondage)

        },
        prepareSondage(sondage) {
            sondage.addEventListener('click', e => {
                const target = e.target;
                const sondageId = sondage.dataset.id;
                let choixId = false
                const lien = target.closest('[href="#voir-resultats"]');
                if (lien) {
                    choixId = true
                }

                const button = target.closest('button[data-id]')
                if (button) {
                    choixId = button.dataset.id;
                }
                if (choixId) {
                    emettreEvenement('vote-sondage', {
                        sondageId: sondageId,
                        choixId: choixId
                    }, this);
                }
            })
        },
        start() {
            Sondages().forEach(sondage => {
                this.prepareSondage(sondage)
            })
            document.addEventListener('voir-resultats-sondage', e => {
                this.voirResultatsSondage(e.data.sondageId, e.data.resultats)
            });

        }
    }
})();